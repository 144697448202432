
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUITopBar from "@/views/resources/documentation/element-ui/navigation/nav-menu/TopBar.vue";
import EUISideBar from "@/views/resources/documentation/element-ui/navigation/nav-menu/SideBar.vue";
import EUICollapse from "@/views/resources/documentation/element-ui/navigation/nav-menu/Collapse.vue";

export default defineComponent({
  name: "nav-menu",
  components: {
    EUITopBar,
    EUISideBar,
    EUICollapse
  },
  setup() {
    setCurrentPageTitle("NavMenu");
  }
});
